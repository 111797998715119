import { useTranslation } from "react-i18next";
import MainTitle from "../../components/title/MainTitle";
import "./services.css";
function Services() {
  const [t] = useTranslation("global");
  return (
    <section className="about-us services">
      <MainTitle text={t("header.services")} />
      <div className="container">
        <div
          className="about-box"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          <Icon />
          <p>{t("services.headerOne")}</p>
        </div>
        <div
          className="about-box"
          data-aos="fade-down"
          data-aos-duration="1200"
        >
          <Icon />
          <p>{t("services.headerTwo")}</p>
        </div>
        <div
          className="about-box"
          data-aos="fade-down"
          data-aos-duration="1400"
        >
          <Icon />
          <p>{t("services.headerThree")}</p>
        </div>
        <div
          className="about-box"
          data-aos="fade-down"
          data-aos-duration="1600"
        >
          <Icon />
          <p>{t("services.headerFour")}</p>
        </div>
        <div
          className="about-box"
          data-aos="fade-down"
          data-aos-duration="1800"
        >
          <Icon />
          <p>{t("services.headerFive")}</p>
        </div>
        <div
          className="about-box"
          data-aos="fade-down"
          data-aos-duration="2000"
        >
          <Icon />
          <p>{t("services.headerSeven")}</p>
        </div>
      </div>
    </section>
  );
}
function Icon() {
  return (
    <div className="number">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 24 24"
      >
        <path
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          d="M6 9a3 3 0 1 0 0-6a3 3 0 0 0 0 6Zm0-6V0m0 12V9M0 6h3m6 0h3M2 2l2 2m4 4l2 2m0-8L8 4M4 8l-2 2m16 2a3 3 0 1 0 0-6a3 3 0 0 0 0 6Zm0-6V3m0 12v-3m-6-3h3m6 0h3M14 5l2 2m4 4l2 2m0-8l-2 2m-4 4l-2 2m-5 8a3 3 0 1 0 0-6a3 3 0 0 0 0 6Zm0-6v-3m0 12v-3m-6-3h3m6 0h3M5 14l2 2m4 4l2 2m0-8l-2 2m-4 4l-2 2"
        />
      </svg>
    </div>
  );
}
export default Services;
