import React from "react";
import "./projects.css";

import { useTranslation } from "react-i18next";
import MainTitle from "../../components/title/MainTitle";
import { Link } from "react-router-dom";
const Projects = () => {
  const [t] = useTranslation("global");
  return (
    <div className="clients">
      <MainTitle text={t("header.projects")} />
      <div className="container">
        <div className="client-boxes project-boxes">
          <Project
            image={
              "https://srgsa.com/uploads/images/f531a9d100f6d3f570d3092c9c5a8bc7.jpg"
            }
            name={t("clientPara.headerTwo")}
            description={t("clientDesc.headerTwo")}
            clientInfo={t("clientInfo.headerTwo")}
            to="https://srgsa.com/uploads/images/f531a9d100f6d3f570d3092c9c5a8bc7.jpg"
            t={t}
          />
          <Project
            image={
              "https://softimageadv.com/wp-content/uploads/2022/09/DSC02167.jpg"
            }
            name={t("clientPara.headerThree")}
            clientInfo={t("clientInfo.headerThree")}
            to="https://softimageadv.com/wp-content/uploads/2022/09/DSC02167.jpg"
            t={t}
          />
          <Project
            image={
              "https://softimageadv.com/wp-content/uploads/2022/09/IMG_9526.jpg"
            }
            name={t("clientPara.headerFour")}
            description={t("clientDesc.headerThree")}
            clientInfo={t("clientInfo.headerFour")}
            to="https://softimageadv.com/wp-content/uploads/2022/09/IMG_9526.jpg"
            t={t}
          />
        </div>
      </div>
    </div>
  );
};

function Project({
  image = "",
  name = "",
  description = "",
  clientInfo = "",
  to = "",
  t
}) {
  return (
    <div className="client-box ">
      <div className="client-img">
        <img src={image} alt={`${name} ${description} `} />
      </div>
      <p className="client-description">
        <span className="client-name">{name}</span> {description}
      </p>
      <p className="client-info">{clientInfo}</p>
      <Link to={to} target="_blank" className="project-cta">
        {t("header.viewP")}
      </Link>
    </div>
  );
}

export default Projects;
