import React, { useState } from "react";
import "./about.css";
import MainTitle from "../../components/title/MainTitle";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const About = () => {
  const [t] = useTranslation("global");
  return (
    <>
      <AboutDetails t={t} />
      <AboutUs>
        {" "}
        <section className="about-us">
          <MainTitle text={t("header.overview")} />
          <div className="container">
            <div
              className="about-box"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <div className="number">1</div>
              <p>{t("header.overviewHeading1")}</p>
            </div>
            <div
              className="about-box"
              data-aos="fade-down"
              data-aos-duration="1200"
            >
              <div className="number">2</div>
              <p>{t("header.overviewHeading2")}</p>
            </div>
            <div
              className="about-box"
              data-aos="fade-down"
              data-aos-duration="1400"
            >
              <div className="number">3</div>
              <p>{t("header.overviewHeading3")}</p>
            </div>
          </div>
        </section>
      </AboutUs>
      <Mission t={t} />
      <WorkScope t={t} />
    </>
  );
};
function AboutDetails({ t }) {
  return (
    <section className="about-details">
      <div className="container">
        <div className="contentRight">
          <div className="content">
            <h1 data-aos="fade-left" data-aos-duration="1000">
              {" "}
              {t("header.aboutTwo")}
            </h1>
            <h4 data-aos="fade-left" data-aos-duration="1000">
              {t("header.Eitamed")}
            </h4>
            <p data-aos="fade-up" data-aos-duration="1200">
              {t("header.EitamedDescription")}
            </p>
            <Link data-aos="fade-up" data-aos-duration="1200" to="/contact">
              {t("header.contact")}
            </Link>
          </div>
        </div>
        <div
          className="contentLeft"
          data-aos="fade-right"
          data-aos-duration="1400"
        >
          <div className="row">
            <div className="imgWrapper">
              <img
                src="/assets/about/Screenshot 2024-06-29 043457.png"
                alt={t("header.Eitamed")}
              />
            </div>
            <div className="imgWrapper">
              <img
                src="/assets/about/Screenshot 2024-06-29 043555.png"
                alt={t("header.Eitamed")}
              />
            </div>

            <div className="imgWrapper">
              <img
                src="/assets/about/Screenshot 2024-06-29 043617.png"
                alt={t("header.Eitamed")}
              />
            </div>
            <div className="imgWrapper">
              <img
                src="/assets/about/Screenshot 2024-06-29 043635.png"
                alt={t("header.Eitamed")}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export function AboutUs({ children }) {
  return children;
}
export function Mission({ t }) {
  return (
    <section className="our-mission">
      <div className="main-heading">
        <div className="arrow-2">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h1>{t("header.mission")}</h1>
        <div className="arrow">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className="container">
        <div
          className="success-box "
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <g
              fill="white"
              stroke="#fdb728"
              strokeLinecap="round"
              strokeLinejoin="round"
              color="#fdb728"
            >
              <path d="M4.222 21.995v-3.55c0-1.271-.333-1.932-.987-3.037A8.888 8.888 0 0 1 10.889 2a8.89 8.89 0 0 1 8.889 8.887c0 .58 0 .87.024 1.032c.058.388.24.722.417 1.068L22 16.441l-1.4.7c-.405.202-.608.303-.749.49s-.181.399-.26.82l-.008.042c-.183.968-.384 2.036-.95 2.71c-.2.237-.448.43-.727.567c-.461.225-1.028.225-2.162.225c-.525 0-1.051.012-1.576 0c-1.243-.031-2.168-1.077-2.168-2.29" />
              <path d="M14.388 10.532c-.426 0-.815-.162-1.11-.427m1.11.426c0 1.146-.664 2.235-1.942 2.235S10.504 13.854 10.504 15m3.884-4.469c2.15 0 2.15-3.35 0-3.35q-.294.001-.557.095c.105-2.498-3.496-3.176-4.312-.836m.985 1.857c0-.774-.39-1.456-.985-1.857m0 0c-1.852-1.25-4.32.993-3.146 2.993c-1.97.295-1.76 3.333.247 3.333a1.66 1.66 0 0 0 1.362-.712" />
            </g>
          </svg>
          <p>{t("mission.headerOne")}</p>
        </div>
        <div
          className="success-box"
          data-aos="fade-left"
          data-aos-duration="1200"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 640 512"
          >
            <path
              fill="white"
              d="M72 88a56 56 0 1 1 112 0a56 56 0 1 1-112 0m-8 157.7c-10 11.2-16 26.1-16 42.3s6 31.1 16 42.3zm144.4-49.3C178.7 222.7 160 261.2 160 304c0 34.3 12 65.8 32 90.5V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32v-26.8C26.2 371.2 0 332.7 0 288c0-61.9 50.1-112 112-112h32c24 0 46.2 7.5 64.4 20.3zM448 416v-21.5c20-24.7 32-56.2 32-90.5c0-42.8-18.7-81.3-48.4-107.7C449.8 183.5 472 176 496 176h32c61.9 0 112 50.1 112 112c0 44.7-26.2 83.2-64 101.2V416c0 17.7-14.3 32-32 32h-64c-17.7 0-32-14.3-32-32m8-328a56 56 0 1 1 112 0a56 56 0 1 1-112 0m120 157.7v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM320 32a64 64 0 1 1 0 128a64 64 0 1 1 0-128m-80 272c0 16.2 6 31 16 42.3v-84.6c-10 11.3-16 26.1-16 42.3m144-42.3v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zm64 42.3c0 44.7-26.2 83.2-64 101.2V448c0 17.7-14.3 32-32 32h-64c-17.7 0-32-14.3-32-32v-42.8c-37.8-18-64-56.5-64-101.2c0-61.9 50.1-112 112-112h32c61.9 0 112 50.1 112 112"
            />
          </svg>
          <p>{t("mission.headerTwo")}</p>
        </div>
        <div
          className="success-box "
          data-aos="fade-left"
          data-aos-duration="1400"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <path
              fill="white"
              d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12c5.16-1.26 9-6.45 9-12V5zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11z"
            />
          </svg>
          <p>{t("mission.headerThree")}</p>
        </div>
        <div
          className="success-box "
          data-aos="fade-left"
          data-aos-duration="1600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
          >
            <path
              fill="white"
              fillRule="evenodd"
              d="M11.875 3.418a6 6 0 1 0 .707.707l-2.46 2.46l-1.156 1.156a1 1 0 1 1-.707-.707l.757-.757a2 2 0 0 0-2.43 3.137a.5.5 0 1 1-.707.707a3 3 0 0 1 3.86-4.567l.714-.714A4 4 0 1 0 8 12a.5.5 0 1 1 0 1a5 5 0 1 1 3.164-8.871l.71-.71zm.709-.709a7 7 0 1 0 .707.707l.366-.366a.5.5 0 1 0-.707-.707z"
            />
          </svg>
          <p>{t("mission.headerFour")}</p>
        </div>
      </div>
    </section>
  );
}
function WorkScope({ t }) {
  const [activeIndex, setActive] = useState(2);

  return (
    <section className="work-scope">
      <MainTitle text={t("header.workScope")} />
      <div className="container">
        <Panel>
          {" "}
          <div
            className={`panel ${activeIndex === 0 ? "active" : ""}`}
            onClick={() => setActive(0)}
            style={{
              backgroundImage:
                "url('/assets/about/Screenshot 2024-06-29 043457.png')",
            }}
          >
            <h3>{t("header.Eitamed")}</h3>
          </div>{" "}
        </Panel>
        <Panel>
          {" "}
          <div
            className={`panel ${activeIndex === 1 ? "active" : ""}`}
            onClick={() => setActive(1)}
            style={{
              backgroundImage:
                "url('/assets/about/Screenshot 2024-06-29 043528.png')",
            }}
          >
            <h3>{t("header.Eitamed")}</h3>
          </div>{" "}
        </Panel>
        <Panel>
          {" "}
          <div
            className={`panel ${activeIndex === 2 ? "active" : ""}`}
            onClick={() => setActive(2)}
            style={{
              backgroundImage:
                "url('/assets/about/Screenshot 2024-06-29 043555.png')",
            }}
          >
            <h3>{t("header.Eitamed")}</h3>
          </div>{" "}
        </Panel>

        <Panel>
          {" "}
          <div
            className={`panel ${activeIndex === 4 ? "active" : ""}`}
            onClick={() => setActive(4)}
            style={{
              backgroundImage:
                "url('/assets/about/Screenshot 2024-06-29 043617.png')",
            }}
          >
            <h3>{t("header.Eitamed")}</h3>
          </div>{" "}
        </Panel>
        <Panel>
          {" "}
          <div
            className={`panel ${activeIndex === 5 ? "active" : ""}`}
            onClick={() => setActive(5)}
            style={{
              backgroundImage:
                "url('/assets/about/Screenshot 2024-06-29 043635.png')",
            }}
          >
            <h3>{t("header.Eitamed")}</h3>
          </div>{" "}
        </Panel>
      </div>
    </section>
  );
}
function Panel({ children }) {
  return children;
}
export default About;
