import React from "react";
import "./home.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Services from "../Services/Services";
import { Mission } from "../about/About";
import Industry from "../industry/Industry";

const Home = () => {
  const [t] = useTranslation("global");
  return (
    <>
      <Hero t={t} />
      <Services t={t} />
      <Mission t={t} />
      <Industry t={t} />
    </>
  );
};
function Hero({ t }) {
  return (
    <section className="hero-section">
      <div className="container">
        <div
          className="hero-description"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <h1
            className={
              localStorage.getItem("lang") === `"ar"`
                ? "ar"
                : localStorage.getItem("lang") === `"en"`
                ? "en"
                : ""
            }
          >
            {" "}
            {t("Eitamed.headerOne")}
            <span> {t("Eitamed.headerThree")}</span>{" "}
          </h1>
          <p
            className={
              localStorage.getItem("lang") === `"ar"`
                ? "ar"
                : localStorage.getItem("lang") === `"en"`
                ? "en"
                : ""
            }
          >
            {t("Eitamed.headerTwo")}
          </p>
          <Link className="show-more" to={"/about"}>
            <span>{t("hero.learnMore")}</span>
            {localStorage.getItem("lang") === `"ar"` ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <path d="m12.718 4.707-1.413-1.415L2.585 12l8.72 8.707 1.413-1.415L6.417 13H20v-2H6.416l6.302-6.293z" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <path d="M11.293 4.707 17.586 11H4v2h13.586l-6.293 6.293 1.414 1.414L21.414 12l-8.707-8.707-1.414 1.414z" />
              </svg>
            )}
          </Link>
        </div>

        <img
          src={process.env.PUBLIC_URL + "/assets/header/profile.jpeg"}
          alt={t("header.Eitamed")}
        />
      </div>
    </section>
  );
}

export default Home;
