/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "./location.css";

const Location = () => {
  return (
    <div>
      <div className="map-iframe">

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3627.892576045944!2d46.8679878!3d24.592903300000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2fa77995f24be9%3A0x770cfbf7d8d7d7e0!2z2KXYudiq2YXYryDZhNiq2YbYuNmK2YUg2KfZhNmF2LnYp9ix2LYg2YjYp9mE2YXYpNiq2YXYsdin2Ko!5e0!3m2!1sar!2seg!4v1720699504867!5m2!1sar!2seg"
          width="100%"
          height="600"
          allowfullscreen=""
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Location;
