import { Link } from "react-router-dom";
import "./contact.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
function Contact() {
  return (
    <>
      <ContactDetails />
    </>
  );
}
function ContactDetails() {
  const [t] = useTranslation("global");
  useEffect(function () {
    function Animation() {
      const inputs = document.querySelectorAll(".input");

      function focusFunc() {
        let parent = this.parentNode;
        parent.classList.add("focus");
      }

      function blurFunc() {
        let parent = this.parentNode;
        if (this.value === "") {
          parent.classList.remove("focus");
        }
      }

      inputs.forEach((input) => {
        input.addEventListener("focus", focusFunc);
        input.addEventListener("blur", blurFunc);
      });
    }
    Animation();
  });

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  let contactSubmitHandler = (e) => {
    e.preventDefault();

    if (!name) {
      return toast.error("برجاء كتابة الاسم..!");
    }
    if (!email) {
      return toast.error("برجاء كتابة الايميل..!");
    }
    if (!phone) {
      return toast.error("برجاء كتابة رقم الهاتف..!");
    }
    if (!message) {
      return toast.error("برجاء كتابة الرسالة..!");
    }
  };

  useEffect(() => {
    const contactForm = document.querySelector(".contactus-form");
    let name = document.querySelector(".name-input");
    let email = document.querySelector(".email-input");
    let phone = document.querySelector(".phone-input");
    let message = document.querySelector(".message-input");

    contactForm.addEventListener("submit", () => {
      let formData = {
        name: name.value,
        email: email.value,
        phone: phone.value,
        message: message.value,
      };

      let xhr = new XMLHttpRequest();

      xhr.open("POST", "https://eitamed-back.vercel.app/send-message");
      xhr.setRequestHeader("content-type", "application/json");

      xhr.onload = async function () {
        try {
          toast.success("تم ارسال الرسالة بنجاح");
          name.value = "";
          email.value = "";
          message.value = "";
        } catch (err) {
          toast.error("Something went wrong..!");
        }
      };

      xhr.send(JSON.stringify(formData));
    });
  }, []);

  return (
    <section className="contact-details">
      <div className="container">
        <span className="big-circle"></span>
        <img src="img/shape.png" className="square" alt="" />
        <div className="form">
          <div
            className="contact-form"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <span className="circle one"></span>
            <span className="circle two"></span>

            <form
              className="contactus-form"
              onSubmit={contactSubmitHandler}
              autocomplete="off"
            >
              <h3 className="title">{t("contact.headerFour")}</h3>
              <div className="input-container">
                <input
                  type="text"
                  name="name"
                  className="input name-input"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <label for="">{t("contact.headerFive")}</label>
                <span>{t("contact.headerFive")}</span>
              </div>
              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  className="input email-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label for="">{t("contact.headerSix")}</label>
                <span>{t("contact.headerSix")}</span>
              </div>
              <div className="input-container">
                <input
                  type="tel"
                  name="phone"
                  className="input phone-input"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <label for="">{t("contact.headerSeven")}</label>
                <span>{t("contact.headerSeven")}</span>
              </div>
              <div className="input-container textarea">
                <textarea
                  name="message"
                  className="input message-input"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <label for="">{t("contact.headerEight")}</label>
                <span>{t("contact.headerEight")}</span>
              </div>
              <input type="submit" value={t("contact.send")} className="btn" />
            </form>
          </div>
          <div
            className="contact-info"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h3 className="title">{t("contact.headerOne")}</h3>
            <p className="text">{t("contact.headerTwo")}</p>

            <div className="info">
              <div className="information info-icons">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 32 32"
                >
                  <path
                    fill="currentColor"
                    d="m16 24l-6.09-8.6A8.14 8.14 0 0 1 16 2a8.08 8.08 0 0 1 8 8.13a8.2 8.2 0 0 1-1.8 5.13Zm0-20a6.07 6.07 0 0 0-6 6.13a6.2 6.2 0 0 0 1.49 4L16 20.52L20.63 14A6.24 6.24 0 0 0 22 10.13A6.07 6.07 0 0 0 16 4"
                  />
                  <circle cx="16" cy="9" r="2" fill="currentColor" />
                  <path
                    fill="currentColor"
                    d="M28 12h-2v2h2v14H4V14h2v-2H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V14a2 2 0 0 0-2-2"
                  />
                </svg>
                <p>{t("header.AboutInfo")}</p>
              </div>
              <div className="information info-icons">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20zm8-7l8-5V6l-8 5l-8-5v2z"
                  />
                </svg>
                <p>info@etamed.sa</p>
              </div>
              <div className="information info-icons">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24c1.12.37 2.33.57 3.57.57c.55 0 1 .45 1 1V20c0 .55-.45 1-1 1c-9.39 0-17-7.61-17-17c0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1c0 1.25.2 2.45.57 3.57c.11.35.03.74-.25 1.02z"
                  />
                </svg>
                <p>{t("header.AboutInfo2")}</p>
              </div>
            </div>

            <div className="social-media">
              <p>{t("contact.headerThree")}</p>
              <div className="social-icons ">
                <Link to="https://www.facebook.com/profile.php?id=61563020065395">
                  <svg
                    width="20"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
                  </svg>
                </Link>
                <Link to="#">
                  <svg
                    width="20"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z" />
                  </svg>
                </Link>
                <Link to={"https://wa.me/+966534908840"}>
                  <svg
                    width="20"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                  </svg>
                </Link>
                <Link to="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Contact;
