import React, { useState } from "react";
import "./Widget.css";
import { useTranslation } from "react-i18next";
//uil uil-whatsapp
const Widget = ({classN = "", iconName = "", call = false}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const phoneNumber = "0534908840"; // استبدل هذا بالرقم الذي تريده

  const handleWhatsappClick = () => {
    if(!call) return window.open(`tel:${phoneNumber}`);
    setShowTooltip(!showTooltip);
  };

  const handleOpenWhatsapp = () => {
    
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
  };
  const [t] = useTranslation("global");

  return (
    <span
      className={`${iconName} ${showTooltip ? "show-tooltip" : ""}`}
      onClick={handleWhatsappClick}
    >
      <Icon>
        {" "}
        <i className={classN}></i>
      </Icon>
      <div className="tooltip">
        <span>{t("header.contact")}</span>
        <br />
        <span className="phone-number" onClick={handleOpenWhatsapp}>
          {phoneNumber}
        </span>
      </div>
    </span>
  );
};
function Icon({ children }) {
  return children;
}
export default Widget;
