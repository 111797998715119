import React from "react";
import "./clients.css";
import MainTitle from "../../components/title/MainTitle";
import { useTranslation } from "react-i18next";

const Clients = () => {
  const [t] = useTranslation("global");
  return (
    <div className="clients">
      <MainTitle text={t('header.SuccessPartners')}/>
      <div className="container">
        <div className="client-boxes">
          <Client
            image={
              "https://softimageadv.com/wp-content/uploads/2022/09/DSC_0200.jpg"
            }
            name={t('clientPara.headerOne')}
            description={t('clientDesc.headerOne')}
            clientInfo = {t('clientInfo.headerOne')}
          />
          <Client
            image={
              "https://srgsa.com/uploads/images/f531a9d100f6d3f570d3092c9c5a8bc7.jpg"
            }
            name={t('clientPara.headerTwo')}
            description={t('clientDesc.headerTwo')}
            clientInfo = {t('clientInfo.headerTwo')}
          />
          <Client
            image={
              "https://softimageadv.com/wp-content/uploads/2022/09/DSC02167.jpg"
            }
            name={t('clientPara.headerThree')}
            clientInfo = {t('clientInfo.headerThree')}
          />
          <Client
            image={
              "https://softimageadv.com/wp-content/uploads/2022/09/IMG_9526.jpg"
            }
            name={t('clientPara.headerFour')}
            description={t('clientDesc.headerThree')}
            clientInfo = {t('clientInfo.headerFour')}
          />
          <Client
            image={"https://rateyp.com/wp-content/uploads/photo-gallery/imported_from_media_libray/LB720152o.jpg?bwg=1627317159"}
            name={t('clientPara.headerFive')}
            description={t('clientDesc.headerFour')}
            clientInfo = {t('clientInfo.headerFive')}
          />
          <Client
            image={
              "https://softimageadv.com/wp-content/uploads/2022/09/9.jpg"
            }
            name={t('clientPara.headerSix')}
            description={t('clientDesc.headerFive')}
            clientInfo = {t('clientInfo.headerSix')}
          />
          <Client
            image={
              "https://mir-s3-cdn-cf.behance.net/projects/404/916cb3176529865.64c6c4a177365.png"
            }
            name={t('clientPara.headerSeven')}
            description={t('clientDesc.headerSix')}
            clientInfo = {t('clientInfo.headerSeven')}
          />
          <Client
            image={
              "https://creativity-experts.com/wp-content/uploads/2022/04/015A8428-1-scaled.jpg"
            }
            name={t('clientPara.headerEight')}
            description={t('clientDesc.headerSeven')}
            clientInfo = {t('clientInfo.headerEight')}
          />
          <Client
            image={
              "https://mir-s3-cdn-cf.behance.net/projects/404/916cb3176529865.64c6c4a177365.png"
            }
            name={t('clientPara.headerNine')}
            description={t('clientDesc.headerEight')}
            clientInfo = {t('clientInfo.headerNine')}
          />
          <Client
            image={
              "https://mustbee.com/wp-content/uploads/2023/05/WhatsApp-Image-2022-05-09-at-12.21.09-PM-1-600x600.jpeg"
            }
            name={t('clientPara.headerTen')}
            clientInfo = {t('clientInfo.headerTen')}
          />
          <Client
            image={
              "https://sapioneers.com/wp-content/uploads/elementor/thumbs/2-4-q3rep1xw2pn651u5oodcjz7gcf7hkvkqqazfxma0cg.jpg"
            }
            name={t('clientPara.headerTwil')}
            description={t('clientDesc.headerNine')}
            clientInfo = {t('clientInfo.headerTwil')}
          />
        </div>
      </div>
    </div>
  );
};

function Client({ image ='', name ='', description ='',clientInfo= '' }) {
  return (
    <div className="client-box">
      <div className="client-img">
        <img src={image} alt={`${name} ${description} `}  />
      </div>
      <p className="client-description">
        <span className="client-name">{name}</span> {description}
      </p>
      <p className="client-info">{clientInfo}</p>
    </div>
  );
}

export default Clients;
