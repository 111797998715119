import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import React, { useRef } from "react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "./industry.css";
import MainTitle from "../../components/title/MainTitle";
import { useTranslation } from "react-i18next";
function Industry() {
  return (
    <>
      <IndustryDetails />
    </>
  );
}
function IndustryDetails() {
    const [t] = useTranslation("global");

  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  return (
    <section className="industry-details">
      <MainTitle text={t('header.Ourindustry')} />
      <div className="container">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          onAutoplayTimeLeft={onAutoplayTimeLeft}
          className="mySwiper"
        >
          <SwiperSlide>
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/about/Screenshot 2024-06-29 043457.png"
              }
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/about/Screenshot 2024-06-29 043528.png"
              }
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/about/Screenshot 2024-06-29 043555.png"
              }
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/about/Screenshot 2024-06-29 043617.png"
              }
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/about/Screenshot 2024-06-29 043635.png"
              }
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/about/etamed.png"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/about/etamed2.png"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/1b040160-1306-43d9-87e5-07805dc66011.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/1b50032b-5099-4cf1-8c50-c195c20eea96.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/1c978a60-c51c-4cd3-868f-a6d1fa25b38d.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>

          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/3e4d4f90-4b10-422f-9aec-1cc24b931b95.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/16cc1d98-e170-4e4b-b953-6e3033dac701.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/84fddcae-abe1-4a08-a753-5bd257825495.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/261c15c4-345e-4f60-a3f5-de19aec17558.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>

          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/77095cd2-96c5-47b0-8880-f29aa72c6820.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/84209cfa-535a-4906-871d-ea3f9f7282e9.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/87512c12-74f2-4a38-8d00-fbcd973974b0.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/99172fef-6755-49a1-9d81-dfe36d67d232.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/03364208-2dec-43f6-acd3-bb497919cee3.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/a7434143-4a32-4e81-aa94-984a3d094418.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>

          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/aff701c0-261b-471c-8e8a-cd047a6dbdd1.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/b2b5e53f-d28b-4d80-98a0-975e9e9e6c92.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>

          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/b2098e12-d349-45d6-8853-eab7e32ae96e.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>

          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/b4616887-f9ef-4d6c-932b-7764c073e534.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>

          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/d5d04679-0f4c-4177-b594-6721929a4cb7.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/f17d8b05-f23e-437c-8f48-7bde68aa07bd.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/e821b584-3723-4061-810a-9bbfb59501e5.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/d7836d68-0776-4adf-a6d2-0a234b42cf77.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={process.env.PUBLIC_URL + "/assets/Eitamed/d6002e09-c1be-4fa9-a245-f6e97d8c82d2.jpg"}
              alt={t('header.Eitamed')}
            />
          </SwiperSlide>
          <div className="autoplay-progress" slot="container-end">
            <svg viewBox="0 0 48 48" ref={progressCircle}>
              <circle cx="24" cy="24" r="20"></circle>
            </svg>
            <span ref={progressContent}></span>
          </div>
        </Swiper>
      </div>
    </section>
  );
}
export default Industry;
