import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Header from "./components/Header/Header";
import Contact from "./pages/contact/contact";
import Services from "./pages/Services/Services";
import NotFound from "./pages/404/404";
import Industry from "./pages/industry/Industry";
import Footer from "./components/footer/Footer";
import Clients from "./pages/clients/Clients";
import Location from "./pages/location/Location";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Widget from "./components/whatsapp/Widget";
import Projects from "./pages/projects/projects";

function App() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <BrowserRouter>
      <Header />
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/industry" element={<Industry />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/location" element={<Location />} />
        <Route path="/projects" element={<Projects />} />
      </Routes>
      <Footer />
      <Widget classN="uil uil-whatsapp" iconName="whatsapp" call={1} />
      <Widget classN="uil uil-phone-volume" iconName="whatsapp" call={0} />
      <ScrollToTop />
    </BrowserRouter>
  );
}

export default App;
